import React from 'react';
import { Layout } from '../components/layout';

export default function PortfolioService({ pageContext }) {
    const { caption, items } = pageContext;

    const metaProps = {
        title: `${caption} | Portfolio`,
    };

    return (
        <Layout metaProps={metaProps}>
            <section className="section-30 section-sm-top-35 section-xl-top-120 bg-image bg-image-4">
                <div className="shell shell-fluid">
                    <div className="range range-condensed">
                        <div className="cell-xs-12 cell-xl-preffix-1 cell-xl-11">
                            <p className="h6 text-italic font-serif text-regular">Najlepsze momenty naszego salonu</p>
                            <p className="h1">{caption} | Portfolio</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-30 section-sm-50 section-xl-60">
                <div className="shell shell-wide isotope-wrap">
                    <div className="range range-condensed range-sm-center">
                        <div className="cell-xl-11 offset-top-30 offset-sm-top-60">
                            <div className="cell-box">
                                <div data-isotope-layout="fitRows" data-isotope-group="gallery" data-photo-swipe-gallery="gallery" className="row isotope">
                                    {items.map((item, i) => (
                                        <div className="col-xs-12 col-sm-6 col-md-4 isotope-item" key={item}>
                                            <a href={'/images/' + item} data-photo-swipe-item="" data-size="1533x1536" className="thumbnail thumbnail-variant-3">
                                                <figure><img src={'/images/' + item} alt="" width="570" height="380" />
                                                </figure>
                                                <div className="caption">
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div tabIndex="-1" role="dialog" aria-hidden="true" className="pswp">
                <div className="pswp__bg"></div>
                <div className="pswp__scroll-wrap">
                    <div className="pswp__container">
                        <div className="pswp__item"></div>
                        <div className="pswp__item"></div>
                        <div className="pswp__item"></div>
                    </div>
                    <div className="pswp__ui pswp__ui--hidden">
                        <div className="pswp__top-bar">
                            <div className="pswp__counter"></div>
                            <button title="Close (Esc)" className="pswp__button pswp__button--close"></button>
                            <button title="Share" className="pswp__button pswp__button--share"></button>
                            <button title="Toggle fullscreen" className="pswp__button pswp__button--fs"></button>
                            <button title="Zoom in/out" className="pswp__button pswp__button--zoom"></button>
                            <div className="pswp__preloader">
                                <div className="pswp__preloader__icn">
                                    <div className="pswp__preloader__cut">
                                        <div className="pswp__preloader__donut"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                            <div className="pswp__share-tooltip"></div>
                        </div>
                        <button title="Previous (arrow left)" className="pswp__button pswp__button--arrow--left"></button>
                        <button title="Next (arrow right)" className="pswp__button pswp__button--arrow--right"></button>
                        <div className="pswp__caption">
                            <div className="pswp__caption__cent"></div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};